//
// user.scss
// Use this to write your custom SCSS
//
// user.scss
// Use this to write your custom SCSS
//
.hexagon-icon {
  clip-path: polygon(25% 6.7%, 75% 6.7%, 100% 50%, 75% 93.3%, 25% 93.3%, 0% 50%);
}

